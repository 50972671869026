import { Playground, Props } from 'docz';
import RedLiner from "../../../../../src/components/RedLiner/RedLiner";
import { buttonStyle, inputStyle } from "../../../../../src/utils/exampleStyles.ts";
import { CustomInfoBox } from "../../../../../src/utils/exampleComponents.tsx";
import React from 'react';
export default {
  Playground,
  Props,
  RedLiner,
  buttonStyle,
  inputStyle,
  CustomInfoBox,
  React
};